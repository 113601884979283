import React,{useState,useEffect} from 'react';
import './qualification.styles.scss';

const Qualification=() => {

    const [qualificationName,setQualificationName]=useState('Education');

    useEffect(()=>{},[qualificationName])

    const data_education = [
        {postion_degree:'BSc in Computer Science and Engineering',organization:'American International University-Bangladesh (AIUB)',duration:'Jan 2018 - Dec 2021'},
        {postion_degree:'Higher Secondary Certificate',organization:'Cambrian School & College',duration:'Feb 2015 - Apr 2017'},
        {postion_degree:'Secondary School Certificate',organization:'Mirpur Bangla School',duration:'Jan 2013 - Jan 2015'}
    ]
    const data_work = [
        {postion_degree:'mPower Social Enterprises Limited',organization:'Software Engineer',duration:'Mar 2024 - Present'},
        {postion_degree:'Bioforge Health Systems Limited',organization:'Software Engineer',duration:'Feb 2022 - Feb 2024'},
        {postion_degree:'Bioforge Health Systems Limited',organization:'Software Engineer - Intern',duration:'Nov 2021 - Jan 2022'},
        
    ]

    return (
    <div className="Qualification" id='qualification'>
            <h1>Qualification</h1>

            <div style={{textAlign:'center',marginBottom:'3%'}}>
                <button className={qualificationName==="Education"?"btn btnColorSelected":"btn btnColorDefault"} onClick={()=>setQualificationName('Education')}>Education</button>
                <button className={qualificationName!=="Education"?"btn btnColorSelected":"btn btnColorDefault"} onClick={()=>setQualificationName('Work')}>Experience</button>
            </div>

                {/* <div className="qualification__data"> */}

                {qualificationName==='Education'?data_education.map((data,index)=>{
                        return(
                            <div>
                                {index%2?<div className="qualification__data">
                                    <div></div>
                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>
                                    <div className="qualification__text" style={{textAlign: 'left'}}>
                                        <h3 className="qualification_degree">{data.postion_degree}</h3>
                                        <span className="organization">{data.organization}</span>
                                        <div className="duration">
                                            {data.duration}
                                        </div>
                                    </div>

                                </div>
                                :
                                <div className="qualification__data">
                                    <div className="qualification__text" style={{textAlign: 'right'}}>
                                        <h3 className="qualification_degree">{data.postion_degree}</h3>
                                        <span className="organization">{data.organization}</span>
                                        <div className="duration">
                                            {data.duration}
                                        </div>
                                    </div>
                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>   
                            </div> }

                            </div>
                            
                        )})
                    :

                    data_work.map((data,index)=>{
                        return(
                            <div>
                                {index%2?<div className="qualification__data">
                                    <div></div>
                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line2"></span>
                                    </div>
                                    <div className="qualification__text" style={{textAlign: 'left'}}>
                                        <h3 className="qualification_degree">{data.postion_degree}</h3>
                                        <span className="">{data.organization}</span>
                                        <div className="duration">
                                            {data.duration}
                                        </div>
                                    </div>

                                </div>
                                :
                                <div className="qualification__data">
                                    <div className="qualification__text" style={{textAlign: 'right'}}>
                                        <h3 className="qualification_degree">{data.postion_degree}</h3>
                                        <span className="">{data.organization}</span>
                                        <div className="duration">
                                            {data.duration}
                                        </div>
                                    </div>
                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line2"></span>
                                    </div>   
                            </div> }

                            </div>
                            
                        )})
                    
                    }




            

</div>
)
}

export default Qualification;
